<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight">
        <div class="TestPaperCenter">
          <div class="TestPaperCenter-Title">2019年度真题</div>
          <ul>
            <li>
              <div class="TestPaperCenter-tab">
                <div class="TestPaperCenter-topic">选择题</div>
                <div class="TestPaperCenter-time">
                  倒计时：<span>1小时20分</span>
                </div>
              </div>
              <div class="page-center">
                <div class="itemPage">
                  <div class="left"></div>
                  <div class="right"></div>
                  <div class="center">第2题，共3题</div>
                </div>
              </div>
              <div class="TestPaperCenter-Font">
                在GDP的几大部分构成中,不包括
              </div>
              <div class="TestPaperCenter-or">
                <dl>
                  <dd>
                    <div class="TestPaperCenter-orDdTitle">
                      <label>
                        <input checked name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TestPaperCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TestPaperCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TestPaperCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TestPaperCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TestPaperCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TestPaperCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TestPaperCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                </dl>
              </div>
              <div class="page-center">
                <div class="itemPage">
                  <div class="left"></div>
                  <div class="right"></div>
                  <div class="center">第2题，共3题</div>
                </div>
              </div>
            </li>
            <li>
              <div class="TestPaperCenter-tab">
                <div class="TestPaperCenter-topic">名词解释题</div>
              </div>
              <div class="page-center">
                <div class="itemPage">
                  <div class="left"></div>
                  <div class="right"></div>
                  <div class="center">第2题，共3题</div>
                </div>
              </div>
              <div class="TestPaperCenter-Font">什么是贷款？</div>
              <div class="TestPaperCenter-text">
                <textarea></textarea>
              </div>
              <div class="page-center">
                <div class="itemPage">
                  <div class="left"></div>
                  <div class="right"></div>
                  <div class="center">第2题，共3题</div>
                </div>
              </div>
            </li>
          </ul>

          <div class="TestPaperBut">
            <button class="but1" type="button">关闭</button>
            <button class="but2" type="button">提交</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
import { examDetailsById } from "@/api/examController.js";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      show: false,
      info: "对",
    };
  },
  created() {
    this.examDetailsById();
  },
  methods: {
    async examDetailsById() {
      const { code, data } = await examDetailsById();
      alert(code);
      alert(JSON.stringify(data));
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .TestPaperCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .TestPaperCenter-tab {
        margin-top: 25px;
        height: 40px;
        .TestPaperCenter-topic {
          display: block;
          height: 40px;
          background: #636363;
          border: 1px solid #707070;
          border-radius: 20px;
          line-height: 40px;
          padding: 0 25px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          float: left;
        }
        .TestPaperCenter-time {
          float: right;
          line-height: 40px;
          font-size: 16px;
          span {
            color: #8d0005;
          }
        }
      }
      .page-center {
        height: 32px;
        background: url("~@/assets/TestPaper/itembg.png") repeat-x left;
        text-align: center;
        margin-top: 30px;
        .itemPage {
          height: 30px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 15px;
          display: inline-block;
          box-sizing: content-box;
          .left {
            float: left;
            background: url("~@/assets/TestPaper/left.png") no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-right: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .right {
            float: right;
            background: url("~@/assets/TestPaper/right.png") no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-left: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .center {
            padding: 0 70px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #575b66;
          }
          &:hover {
            border: 1px solid #8d0005;
            .left {
              border-right: 1px solid #8d0005;
            }
            .right {
              border-left: 1px solid #8d0005;
            }
            .center {
              color: #8d0005;
            }
          }
        }
      }
      .TestPaperCenter-Font {
        margin-top: 30px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .TestPaperCenter-or {
        margin-top: 30px;
        border: 1px solid #d6d6d6;
        border-radius: 6px;
        dl {
          dd {
            .TestPaperCenter-orDdTitle {
              padding: 20px 14px;
              label {
                cursor: pointer;
                display: block;
                position: relative;
                input {
                  float: left;
                  margin-top: 6px;
                }
                div {
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                }
                i {
                  position: absolute;
                  right: 0px;
                  top: 5px;
                }
              }
            }
            .TestPaperCenter-info {
              background: #e8e8e8;

              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
            }
          }
        }
      }
      .TestPaperCenter-text {
        margin-top: 30px;
        textarea {
          height: 350px;
          width: 100%;
          border: 0;
          font-size: 16px;
          padding: 5px 0;
          line-height: 24px;
        }
      }
    }
  }
}
.TestPaperBut {
  padding: 50px 0;
  text-align: center;
  button {
    height: 44px;

    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.tmItem li::after {
  clear: both;
  display: block;
  content: "";
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
</style>
